import * as React from "react";
import Camera from "V2/camera";
import Layout from "V2/layout";
import { UserContext } from "context/userContext";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { useNavigation } from "utils/onNavigate";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  ELEMENT_ID,
  ERROR,
  PASSKEY_BASE_URL,
  SUCCESS,
} from "constant";
import {
  generateAuthenticationOptions,
  verifyAuthentication,
} from "services/passkey";
import { startAuthentication } from "@simplewebauthn/browser";
import {
  extractRoute,
  getOrganizationFromURL,
  getStatusFromUser,
  stopCamera,
} from "utils";
import { toast } from "components/ui/use-toast";
import { getUser } from "services/api";
import cardBg from "assets/V2/cardBg.png";
import { closeCamera, deleteUser } from "@privateid/cryptonets-web-sdk";
import { UAParser } from "ua-parser-js";
import UserInfoLogin from "V2/userInfoLogin";
import { getCredentialDetails } from "services/vc-dock";

interface componentNameProps {}
let count: number = 0;
const LoginV2 = (props: componentNameProps) => {
  const projectName = getOrganizationFromURL();
  const context: any = React.useContext(UserContext);
  const timeoutRef = React.useRef<any>(null);
  const configuration: any = context?.configuration;
  const pageConfig: any = context?.pageConfiguration;
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari";
  const { navigateToNextPage } = useNavigation();
  const onStatus = () => {};
  const [showCorners, setShowCorners] = React.useState(false);
  const [doneStatus, setDoneStatus] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [showPhone, setShowPhone] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/v2/failure-screen", "/predict-scan");
    }
  };
  const isCardFlow = true || ["crown-v2", "vibe"]?.includes(projectName);
  const onSuccess = () => {
    context.setUser({
      ...context.user,
      uuid: loginPUID,
      guid: loginGUID,
    });
    handelLoginResponse(faceLoginResponseStatus);
  };

  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginResponseStatus,
    faceLoginInputImageData,
    loginGUID,
    loginPUID,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    false,
    onPredictFail
  );

  React.useEffect(() => {
    if (faceLoginResponseStatus === undefined) {
      timeoutRef.current = setInterval(() => {
        count++; // Increment count every second
      }, 1000);
    } else if (faceLoginResponseStatus === 0) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        count = 0;
      }
    }
    return () => {
      if (loaded) {
        count = 0;
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [faceLoginResponseStatus, loaded]);
  // console.log(faceLoginResponseStatus, 'faceLoginResponseStatus');

  React.useEffect(() => {
    if (count >= 45) {
      // Stop the interval
      toast({
        variant: "destructive",
        description: "Face not found",
      });
      window.location.pathname = "/v2/landing";
      count = 0;
    }
  }, [count]);
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(context?.configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const onFailPasskey = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    toast({
      variant: "destructive",
      description: "There was some issue authenticating with passkey.",
    });
    loadPageConfiguration();
    navigateToNextPage("", "/", "/login-dl");
  };

  React.useEffect(() => {
    if (
      context?.loginOption === "prove-checkout-end" ||
      context?.loginOption !== "checkoutScreen"
    ) {
      setShowPhone(false);
    }
  }, [context]);

  // const onAuthenticatePasskey = async () => {
  //   try {
  //     const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
  //     let opts;
  //     let response;
  //     if (context?.loginOption === "pay-with-card") {
  //       opts = await fetch(
  //         PASSKEY_BASE_URL + "/generate-authentication-options",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           credentials: "include",
  //           body: JSON.stringify({ uuid: uuid, cardAuth: true }),
  //         }
  //       );
  //       response = await opts.json();
  //     } else {
  //       response = await generateAuthenticationOptions(uuid);
  //     }
  //     if (response?.challenge) {
  //       let asseResp;
  //       try {
  //         asseResp = await startAuthentication(response);
  //       } catch (error) {
  //         console.log({ error });
  //       }
  //       if (asseResp) {
  //         const verificationJSON = await verifyAuthentication({
  //           asseResp,
  //           uuid,
  //         });
  //         const alreadyEnroll =
  //           verificationJSON?.verified &&
  //           context?.user?.alreadyEnrolled &&
  //           pageConfig?.type === "enrollPasskey";
  //         if (verificationJSON?.verified) {
  //           if (context?.loginOption === "pay-with-card") {
  //             toast({
  //               variant: "success",
  //               description: "Passkey successfully verified",
  //             });
  //             loadPageConfiguration();
  //             navigateToNextPage("", "/", "/login-dl");
  //             return;
  //           }
  //           context.setCheckoutCompleted(true);
  //           context.setSuccessMessage("Success! Your account is created");
  //           if (alreadyEnroll) {
  //             context.setLoginOption("checkoutVc");
  //             context?.setSuccessMessage("Authentication successful!");
  //             if (projectName === "prove") {
  //               navigateToNextPage("", "/cards", "/login-dl");
  //             } else {
  //               navigateToNextPage("", "/vc-proof", "/login-dl");
  //             }
  //           } else if (context?.configuration?.isKantara) {
  //             navigateToNextPage();
  //           } else if (pageConfig) {
  //             context?.setSuccessMessage("Authentication successful!");
  //             if (projectName === "prove") {
  //               navigateToNextPage("", "/cards", "/login-dl");
  //             } else {
  //               navigateToNextPage("", "/vc-proof", "/generate-passkey");
  //             }
  //           } else {
  //             if (projectName === "prove") {
  //               navigateToNextPage("", "/cards", "/login-dl");
  //             } else {
  //               navigateToNextPage("", "/vc-proof", "/generate-passkey");
  //             }
  //           }
  //         } else {
  //           onFailPasskey();
  //         }
  //       } else {
  //         onFailPasskey();
  //       }
  //     } else {
  //       onFailPasskey();
  //     }
  //   } catch (e) {
  //     onFailPasskey();
  //   }
  // };

  const handelLoginResponse = async (result: any) => {
    if ([0, 10].includes(result)) {
      localStorage.setItem("uuid", JSON.stringify(loginPUID || {}));
      const payload = {
        guid: loginGUID,
        uuid: loginPUID,
        checkVC: false,
      };
      // This is for kantara flow
      if (
        context?.configuration?.isKantara &&
        window.location.pathname === "/predict-scan"
      ) {
        context.setUser({
          ...context.user,
          // enrollImageData: faceLoginInputImageData,
        });
        console.log("reached");
        setCompleted(true);
        setTimeout(() => {
          setDoneStatus(true);
        }, 2000);
        navigateToNextPage();
        return;
      }
      if (["aadhaar", "digi-in"].includes(projectName)) {
        localStorage.setItem("user", JSON.stringify({ uuid: loginPUID } || {}));
        handleNextStep();
        return;
      }
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        setTimeout(() => {
          navigateWithQueryParams("/v2/failure-screen");
        }, 2000);
        context.setUser({
          ...context.user,
          data,
        });
        localStorage.setItem("user", JSON.stringify(data.user || {}));
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          if (context.loginOption === "checkoutScreen") {
            localStorage.setItem("user", JSON.stringify(user || {}));
          } else {
            viewCredentials(user);
          }
          handleNextStep();
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          setTimeout(() => {
            navigateWithQueryParams("/v2/failure-screen");
          }, 2000);
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      setTimeout(() => {
        navigateWithQueryParams("/v2/failure-screen");
      }, 2000);
    }
  };

  const viewCredentials = async (User: any) => {
    try {
      const user: any = User || JSON.parse(localStorage.getItem("user") ?? "{}");
      const userId = user?.user || user?._id;
      const credentialDetails: any = await getCredentialDetails(userId);
      const credential = credentialDetails?.credential?.credentialSubject;
      if (credentialDetails?.id) {
        const userData = {
          ...User,
          firstname: credential?.firstname,
          lastname: credential?.lastname,
        };
        context?.setUser({...context?.user, ...userData})
        localStorage.setItem("user", JSON.stringify(userData || {}));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onNavigate = async () => {
    console.log(context.loginOption, "context.loginOption");
    setLoaded(true);
    clearTimeout(timeoutRef.current);
    count = 0;
    if (
      context?.loginOption === "checkoutScreen" &&
      context?.checkoutCompleted &&
      !["crown-v2", "vibe"]?.includes(projectName)
    ) {
      context?.setV2SuccessMessage("You're Checked Out!");
      navigateWithQueryParams("/v2/checkout");
      return;
    } else if (isCardFlow && context.checkoutCompleted) {
      context?.setV2SuccessMessage("You're Checked Out!");
      navigateWithQueryParams("/cards");
      return;
    }
    switch (context.loginOption) {
      case "prove-reset":
        handleDeleteUser();
        break;
      case "prove-checkout":
        context?.setV2SuccessMessage("You're Checked Out!");
        navigateWithQueryParams("/v2/checkout");
        break;
      case "prove-checkout-end":
        context?.setV2SuccessMessage("Checkout successful.");
        navigateWithQueryParams("/v2/success");
        break;
      default:
        context?.setV2SuccessMessage("You're logged in");
        navigateWithQueryParams("/v2/success");
        break;
    }
  };

  const onDeleteUser = () => {
    count = 0;
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    context?.setV2SuccessMessage("Everything is Reset.");
    context.setLoginOption("");
    navigateWithQueryParams("/v2/success");
  };

  const handleDeleteUser = async () => {
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    try {
      deleteUser({ uuid, callback: onDeleteUser });
    } catch (error: any) {
      toast({
        variant: "destructive",
        description: "Failed to delete user",
      });
      navigateToNextPage("", "/", "/");
      return;
    }
  };
  const handleNextStep = async () => {
    count = 0;
    setCompleted(true);
    setDoneStatus(true);
    await closeCamera(ELEMENT_ID);
    await stopCamera();
    setTimeout(() => {
      onNavigate();
    }, 2000);
  };
  const onCameraReady = () => {
    if (context?.loginOption === "face-login-mobile") return;
    faceLoginWithLiveness();
    setShowCorners(true);
  };

  React.useEffect(() => {
    if (loginPUID && loginGUID && faceLoginData && !showPhone) {
      onSuccess();
    }
  }, [loginPUID, loginGUID, faceLoginData, showPhone]);

  const onSuccessPhone = () => {
    setShowPhone(false);
  };
  const isGreenBorder = loginPUID && loginGUID;
  return (
    <Layout
      camera
      innerContainer="max-md:mt-[-78px] max-md:!h-[calc(100vh_-_0rem)]"
    >
      <div className="px-[40px] min-h-full flex flex-col justify-between relative max-md:pt-[40px]">
        {showCorners && (
          <div
            className={`recognition-box  relative z-10 ${
              isGreenBorder ? "!w-[35%] !h-[28%]" : "!w-[60%]"
            }`}
          >
            <div
              className={`corner cornerWhite top-left ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite  top-right ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite bottom-left ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
            <div
              className={`corner  cornerWhite bottom-right ${
                isGreenBorder ? "greenBorder" : ""
              }`}
            ></div>
          </div>
        )}
        {showPhone && context?.loginOption === "checkoutScreen" && (
          <div className="absolute top-[0px] left-[0px] z-[99999999] w-full h-full backdrop-blur-lg pt-[50px]">
            <UserInfoLogin
              onSuccess={onSuccessPhone}
              hideCamera={true}
              isCameraScreen={true}
            />
          </div>
        )}

        {doneStatus ? (
          <div
            className={`${
              doneStatus
                ? `absolute top-0 left-0 w-full flex flex-col items-center justify-center h-[705px] z-[9999999999] backdrop-blur-lg ${
                    isMobileSafari
                      ? "max-md:h-[calc(100vh_-_4rem)]"
                      : "max-md:h-[calc(100vh_-_2rem)]"
                  } ${
                    window.innerWidth < 767
                      ? isMobileSafari
                        ? "[@media_((min-height:700px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                        : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_0rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:1000px))]:h-[calc(100vh_-_0rem)]"
                      : ""
                  }`
                : ""
            }`}
          >
            <div
              className="bg-[#161B25B2] w-[100%] h-[100%] flex items-center justify-center successAnimationNewv2"
              style={{
                // backgroundImage: `url(${cardBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="text-[25px] absolute top-[62px] text-center left-[0px] z-[99999999] w-full bg-transparent bg-opacity-70 text-[#cbd4eb] py-2 rounded-lg">
                {context?.loginOption === "checkoutScreen" ? (
                  <>
                    <p>🎉 Done!</p>
                    <p className="text-[20px] mt-[10px]">Image captured.</p>
                  </>
                ) : (
                  "Processing ..."
                )}
              </div>
              {context?.loginOption === "checkoutScreen" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="svg-success"
                  viewBox="0 0 24 24"
                >
                  <g
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                  >
                    <circle
                      className="success-circle-outline"
                      cx="12"
                      cy="12"
                      r="11.5"
                    />
                    <circle
                      className="success-circle-fill"
                      cx="12"
                      cy="12"
                      r="11.5"
                    />
                    <polyline
                      className="success-tick"
                      points="17,8.5 9.5,15.5 7,13"
                    />
                  </g>
                </svg>
              ) : (
                <span className="processingLoader"></span>
              )}
            </div>
          </div>
        ) : (
          <Camera
            onCameraReady={onCameraReady}
            message={
              faceLoginWithLivenessMessage === "Valid Image"
                ? "Processing still holding."
                : faceLoginWithLivenessMessage ||
                  "Center your head in the frame"
            }
            progress={loginPUID && loginGUID ? 100 : 0}
            // onSuccess={onSuccess}
          />
        )}
      </div>
    </Layout>
  );
};

export default LoginV2;
