import BackButton from "common/components/backButton";
import Layout from "common/layout";
import { Label } from "components/ui/label";
import CardImage from "assets/cardImage.jpg";
import { Button } from "components/ui/button";
// import { useNavigation } from "utils/onNavigate";
import hsbc from "assets/hsbc.webp";
import manzo from "assets/manzo.jpg";
import { useState } from "react";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Prove1 from "assets/proveVisa.png";
import Prove2 from "assets/proveVisa1.png";
import Prove3 from "assets/proveVisa2.png";
import { getOrganizationFromURL } from "utils";

const CardScreen = () => {
  const cards = [
    {
      image: CardImage,
      price: "$55,000",
    },
    {
      image: hsbc,
      price: "$430",
    },
    {
      image: manzo,
      price: "$8,540",
    },
  ];
  const cardsVisa = [
    {
      image: Prove1,
      price: "$55,000",
    },
    {
      image: Prove2,
      price: "$430",
    },
    {
      image: Prove3,
      price: "$8,540",
    },
  ];
  const projectName = getOrganizationFromURL();

  // const { navigateToNextPage } = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [cardIndex, setCardIndex] = useState(0);
  const cardsOption = projectName === "vibe" ? cardsVisa : cards
  return (
    <Layout>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0 w-full">
          <BackButton />
          <Label className="text-[28px] max-md:text-[20px] text-center w-full block font-[Google Sans] font-[500] text-primaryText mb-[0px]">
            Cards
          </Label>
        </div>
        <Label className="text-[14px] max-md:text-[20px] text-center w-full block font-[Google Sans] font-[500] text-primaryText mb-[20px]">
          Select the card you would like to use
        </Label>

        <div className="mt-[0px] rounded-[20px] flex flex-col items-center justify-center h-[396px] max-md:h-[420px]">
          {cardsOption?.map((item, index: any) => {
            return (
              <div
                onClick={() => setCardIndex(index)}
                className={`flex relative w-[40%] max-md:w-[60%] items-center justify-center ${
                  cardIndex === index ? "scale-95 border p-[5px]" : ""
                } hover:scale-95 transition-all mb-[10px] hover:border border-[#000] hover:p-[5px] rounded-[5px]`}
              >
                <img
                  src={item?.image}
                  alt="CardImage"
                  className="rounded-[10px] w-[100%] h-[118px] cursor-pointer"
                />
                {item?.price && (
                  <p className="w-[80px] bg-[#000] text-[#fff] text-[14px] py-[2px] absolute right-[-50px] rounded-[7px] border border-[#fff]">
                    {item?.price}
                  </p>
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-[10px] flex justify-center max-md:mt-[0px]">
          <Button
            className="w-[65%] bg-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-white max-md:w-[100%]"
            onClick={() => navigateWithQueryParams("/vc-proof")}
          >
            Select payment card for checkout
          </Button>
        </div>
        <div
          className="mt-4 flex justify-center max-md:mt-2"
          onClick={() => navigateWithQueryParams("/vc-proof")}
        >
          <Button className="w-[65%] bg-transparent rounded-[24px] underline text-[14px] hover:opacity-90 hover:bg-transparent text-primary max-md:w-[100%]">
            I do not wish to to use a card from my wallet
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CardScreen;
