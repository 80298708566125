import PhoneInput from "common/components/phoneInput";
import { Button } from "components/ui/button";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "V2/layout";
import { useContext } from "react";
import { UserContext } from "context/userContext";
import {
  closeCamera,
  createUserWithSession,
} from "@privateid/cryptonets-web-sdk";
import { isMobile, LoadPageConfiguration, stopCamera } from "utils";
import { useNavigation } from "utils/onNavigate";
import { ELEMENT_ID } from "constant";
import { useEffect } from "react";

interface UserInfoProps {
  onSuccess?: () => void;
  hideCamera?: boolean;
  isCameraScreen?: boolean;
}

const UserInfo = ({ onSuccess, hideCamera, isCameraScreen }: UserInfoProps) => {
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [phone, setPhone] = React.useState<string>("");
  const [loader, setLoader] = React.useState(false);
  const [errors, setErrors] = React.useState({
    phoneError: "",
  });

  React.useEffect(() => {
    LoadPageConfiguration(context, window.location.pathname);
  }, []);

  const onBlur = (type: string) => {
    console.log("errror");
    switch (type) {
      case "phone":
        setErrors({
          ...errors,
          phoneError: "",
        });
        return;
      default:
        break;
    }
  };

  const handleContinue = async () => {
    if (phone.length < 10) {
      setErrors({ phoneError: "Invalid Number" });
      return;
    }
    setLoader(true);
    if (!token) {
      console.error("Session token is missing.");
      return;
    }
    const payload = {
      sessionToken: token,
      phone: phone,
    };
    context.setUser({ ...context.user });

    const userResult = await createUserWithSession(payload);

    if (userResult?.success) {
      if (onSuccess) {
        onSuccess();
      } else {
        await closeCamera(ELEMENT_ID);
        await stopCamera();
        navigateToNextPage();
      }
    } else if (userResult?.statusCode === 406) {
      if (onSuccess) {
        onSuccess();
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (phone?.length >= 10) {
      handleContinue();
    }
  }, [phone]);
  const renderComponent = () => {
    return (
      <div className="h-[95%] max-md:h-[100%] z-[9999] relative">
        <p className="text-[32px]  mt-[0px] mb-[30px]  text-center text-white font-[500]">
          Enter phone number
        </p>
        <div className="max-md:px-[21px]  w-full px-[40px] flex flex-col h-[89.4%] max-md:h-[85%]">
          <div
            className={`flex-1 max-md:flex-[unset]  ${
              errors?.phoneError ? "error-wrap" : "phone-wrap "
            }`}
          >
            <PhoneInput
              setValue={setPhone}
              placeholder={"Phone Number"}
              ariaLabel={"Phone Number"}
              phone={phone}
              handleFocus={() => onBlur("phone")}
              autoFocus={isMobile}
            />
            <p className="text-[#f00] text-center pt-5 font-[600]">
              {errors?.phoneError}
            </p>
          </div>
          <Button
            className=" max-md:mb-[0px] w-full text-black bg-white rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={handleContinue}
            disabled={loader}
          >
            {pageConfig?.buttonText || "Continue"}
          </Button>
        </div>
      </div>
    );
  };
  return isCameraScreen ? (
    <>{renderComponent()}</>
  ) : (
    <Layout
      className={`${isMobile && "userInfoAnimation"} ${
        hideCamera && "bg-transparent"
      }`}
      allowCamera={!hideCamera}
    >
      {renderComponent()}
    </Layout>
  );
};

export default UserInfo;
