import bloom from "assets/V2/bloom.jpg";
import { Button } from "components/ui/button";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { getOrganizationFromURL } from "utils";
import { useNavigation } from "utils/onNavigate";

const Landing = () => {
  const {
    setLoginOption,
    setV2SuccessMessage,
    configuration,
    setCheckoutCompleted,
  }: any = useContext(UserContext);
  const context: any = useContext(UserContext);
  const [user, setUser] = useState<any>({});

  const onUser = () => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    // const user = userData?.firstname ? userData : context?.user
    if (userData?.firstname || userData?.lastname) {
      setUser(userData);
    }
  };

  useEffect(() => {
    onUser();
  }, []);
  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    setUser({});
    context?.setUser({})
    onUser();
  };
  const projectName = getOrganizationFromURL();
  const { navigateToNextPage } = useNavigation();
  const isCardFlow = true || ["crown-v2", "vibe"]?.includes(projectName);
  const onSignup = () => {
    setLoginOption("");
    navigateToNextPage("", "/v2/consent", "/");
  };
  const onLogin = () => {
    setLoginOption("");
    if (!isCardFlow) {
      setLoginOption("prove-checkout");
    }
    navigateToNextPage("", "/v2/login", "/");
  };

  const onReset = () => {
    setLoginOption("prove-reset");
    navigateToNextPage("", "/v2/login", "/");
  };

  const onCheckout = () => {
    setCheckoutCompleted(false);
    setV2SuccessMessage("You're Checked Out!");
    setLoginOption("checkoutScreen");
    navigateToNextPage("", "/v2/checkout", "/");
  };

  return (
    <div>
      <div className="flex justify-end p-[20px]">
        {user?.firstname || user?.lastname ? (
          <>
            <div className="relative">
              <div className="flex items-center">
                <div className="relative mr-[10px] w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mt-[-5px] cursor-pointer z-50">
                  <svg
                    className="absolute w-10 h-10 text-gray-400 -left-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <p>
                  {user?.firstname} {user?.lastname}
                </p>
                {/* <ChevronDown
                  className={`cursor-pointer w-[20px] ml-[5px] ${
                    isMenu && "rotate-180"
                  } transition-all`}
                /> */}
              </div>
            </div>
            <Button
              className="w-[130px] bg-transparent border-2 border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-primary text-primary hover:text-white max-md:w-[100%] max-md:text-[13px] max-md:max-w-[80px] ml-[10px]"
              onClick={onLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            {isCardFlow && (
              <button
                className="text-primary hover:underline mr-[20px]"
                onClick={onCheckout}
              >
                Checkout with card
              </button>
            )}

            <button className="text-primary hover:underline" onClick={onLogin}>
              Sign in
            </button>
            <button
              className="ml-[20px] bg-primary text-white px-[30px] py-[4px] rounded-[40px] pb-[6px] scale-110"
              onClick={onSignup}
            >
              Sign up
            </button>
          </>
        )}
      </div>
      <div
        className={
          isCardFlow
            ? "h-[calc(100vh_-74px)] flex justify-center items-center"
            : ""
        }
      >
        <img
          src={isCardFlow ? configuration?.bannerImage : bloom}
          alt="bloom"
          className={`m-auto ${isCardFlow && "w-[400px] max-md:w-[300px]"}`}
        />
      </div>
      <div className="fixed right-[20px] bottom-[20px] opacity-30">
        <span className="cursor-pointer hover:underline" onClick={onReset}>
          Reset
        </span>
      </div>
    </div>
  );
};

export default Landing;
