import { closeCamera } from "@privateid/cryptonets-web-sdk";
import { Label } from "components/ui/label";
import { ELEMENT_ID } from "constant";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCredentialDetails } from "services/vc-dock";
import { LoadPageConfiguration, stopCamera } from "utils";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Layout from "V2/layout";

interface componentNameProps {}

const OtpSuccess = (props: componentNameProps) => {
  const context: any = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const [userData, setUserData] = useState<any>({});
  const user: any = JSON.parse(localStorage.getItem("user") ?? "{}");
  const userId = user?.user || user?._id;
  const [processingLoader, setProcessingLoader] = useState(true);
  // const viewCredentials = async () => {
  //   try {
  //     const credentialDetails: any = await getCredentialDetails(userId);
  //     const credential = credentialDetails?.credential?.credentialSubject;
  //     if (credentialDetails?.id) {
  //       const userData = {
  //         ...user,
  //         firstname: credential?.firstname,
  //         lastname: credential?.lastname,
  //       };
  //       setUserData(userData);
  //       // localStorage.setItem("user", JSON.stringify(userData));
  //     }
  //     setTimeout(() => {
  //       setProcessingLoader(false);
  //     }, 1000)
  //     return;
  //   } catch (e) {setProcessingLoader(false);}
  // };
  useEffect(() => {
    // if (userId && !token) {
    //   // viewCredentials();
    // } else {
      setProcessingLoader(false)
    // }
  }, [userId, token]);
  useEffect(() => {
    if (!processingLoader) {
      setTimeout(async () => {
        await closeCamera(ELEMENT_ID);
        await stopCamera();
        LoadPageConfiguration(context, "/");
        navigateWithQueryParams("/", { token: undefined });
      }, 4000);
    }
  }, [processingLoader]);
  const capitalizedFirstName = userData?.firstname
    ? userData.firstname.charAt(0).toUpperCase() +
      userData.firstname.slice(1).toLocaleLowerCase()
    : "";
  return (
    <Layout logoBackgroundColor={"rgb(22,27,37)"} backgroundColor={"#161b25"}>
      <div className="pt-[50px] max-md:px-[8px] px-[40px] h-[100%] flex flex-col justify-between ">
        <div className="flex-grow">
          <Label
            className="text-[25px] font-[400] text-white block text-center"
            dangerouslySetInnerHTML={{
              __html: processingLoader
                ? "Processing ..."
                : context?.v2SuccessMessage || "Success!",
            }}
          ></Label>
          {!["Checkout successful.", "Everything is Reset."]?.includes(context?.v2SuccessMessage) && (
            <Label className="text-[15px]  font-[400] text-white block text-center opacity-80">
              {processingLoader
                ? ""
                : token?.length > 0
                ? `Age verified as over 21`
                : `Welcome back`}
            </Label>
          )}
          <div className="h-[80%] w-[100%] flex items-center justify-center ">
            {processingLoader ? (
              <span className="processingLoader"></span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="svg-success"
                viewBox="0 0 24 24"
              >
                <g
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                >
                  <circle
                    className="success-circle-outline"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <circle
                    className="success-circle-fill"
                    cx="12"
                    cy="12"
                    r="11.5"
                  />
                  <polyline
                    className="success-tick"
                    points="17,8.5 9.5,15.5 7,13"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OtpSuccess;
