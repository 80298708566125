import { useContext, useState } from "react";
import config from "config";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createVerificationSession } from "services/api";
import Layout from "V2/layout";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";

const Consent = () => {
  const { configuration, setTokenParams }: any = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const context = useContext(UserContext)
  const pageConfig: any = context?.pageConfiguration;

  const { navigateToNextPage } = useNavigation();

  const handleContinue = async () => {
    try {
      setLoader(true);
      const payload: any = {
        ...config?.clientConfig,
        productGroupId:
          configuration?.productGroupId ||
          config?.clientConfig?.productGroupId ||
          "priflo",
      };
      const result: any = await createVerificationSession(payload);
      setTokenParams(result?.token?.replace("?", ""));
      const params = `token=${result?.token?.replace("?", "")}${
        searchParams.get("skipAntispoof")
          ? `&skipAntispoof=${searchParams.get("skipAntispoof")}`
          : ""
      }`;
      setLoader(false);
      navigateToNextPage(params);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <Layout logoBackgroundColor={"rgb(22,27,37)"} backgroundColor={"#161b25"}>
      <div className=" max-md:pt-[10px] pt-[40px] max-md:px-[0px] px-[40px] h-[100%] flex flex-col justify-between">
        <div className="flex-grow">
          <p className="text-[25px] font-[600] text-white block text-center max-md:font-[400]">
            {pageConfig?.title || 'Take a selfie to register'}
          </p>
          <p className="text-[15px]  font-[400] text-white block text-center opacity-80">
            {pageConfig?.acknowledgeText || 'No images will leave your device.'}
          </p>
          <div className="border-b mx-auto  pt-4 opacity-20"></div>
          <div className="flex flex-col m-auto   max-md:w-[100%] max-md:p-[10px]">
            <h5 className="text-[18px] pb-2 mx-auto text-white mt-[20px] font-[700] max-md:mt-[10px]  max-md:font-[400]">
              User consent
            </h5>
            <p className="mt-[0px] text-[15px] text-white opacity-70">
              By clicking the 'Agree and continue' button below, you acknowledge
              that you are over eighteen (18) years of age, have read the Prove
              LLC and Private Identity LLC{" "}
              <a href="/" className="underline">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="/" className="underline">
                Terms of Use
              </a>{" "}
              and understand how your personal data will be processed in
              connection with your use of this Identity Verification Service.
            </p>
            <p className="mt-[10px] text-[15px] font-[Google Sans] text-white  opacity-70">
              You agree to receive SMS messages for verification purposes.
              Standard message and data rates may apply.
            </p>
            <p className="mt-[10px] text-[15px] font-[Google Sans] text-white  opacity-70">
              <a href="/" className="underline">
                Learn
              </a>{" "}
              how identity verification works.
            </p>
          </div>
        </div>
        <div className="flex flex-col space-y-4 justify-center items-center pb-2 max-md:pb-[0rem]">
          <Button
            className="w-full text-black bg-white text-[16px] rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={handleContinue}
            disabled={loader}
          >
            {pageConfig?.buttonText || 'Agree and continue'}
          </Button>
          <Button
            className="w-full text-black bg-white text-[16px] rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={() => {
              navigateToNextPage('', "/", "/v2/consent");
            }}
          >
            No Thanks
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Consent;
