import { Button } from "components/ui/button";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "V2/layout";
import { useContext } from "react";
import { UserContext } from "context/userContext";
import { isMobile, LoadPageConfiguration, stopCamera } from "utils";
import { useNavigation } from "utils/onNavigate";
import DriversLicense from "../../assets/driver-licence.svg";
import PassportIcon from "../../assets/passport-icon.svg";
import { Label } from "components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";

const options = [
  {
    label: "Driver’s license or National/State ID",
    value: "drivers-licence",
    image: DriversLicense,
  },
  {
    label: "Passport",
    value: "passport",
    image: PassportIcon,
  },
];
interface UserInfoProps {
  onSuccess?: () => void;
  hideCamera?: boolean;
  isCameraScreen?: boolean;
}

const DocSelectionV2 = ({
  onSuccess,
  hideCamera,
  isCameraScreen,
}: UserInfoProps) => {
  const { navigateToNextPage } = useNavigation();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [phone, setPhone] = React.useState<string>("");
  const [loader, setLoader] = React.useState(false);
  const [errors, setErrors] = React.useState({
    phoneError: "",
  });

  React.useEffect(() => {
    LoadPageConfiguration(context, window.location.pathname);
  }, []);
  const [flow, setFlow] = React.useState<any>("drivers-licence");

  const handleContinue = async () => {
    if (flow === "drivers-licence") {
        navigateToNextPage('', '/v2/driver-license', '/v2/doc-selection');
    } else {
        navigateToNextPage('', '/v2/passport', '/v2/doc-selection');
    }
  };

  const onFlowChange = (flow: string) => {
    setFlow(flow);
  };
  const renderComponent = () => {
    return (
      <div className="h-[95%] max-md:h-[100%] z-[9999] relative">
        <p className="text-[32px]  mt-[0px] mb-[30px]  text-center text-white font-[500]">
          Verify your identity
        </p>
        <div className="max-md:px-[21px]  w-full px-[40px] flex flex-col h-[90%] max-md:h-[65vh]">
          <div className={`flex-1 max-md:flex-[unset]`}>
            <RadioGroup defaultValue="drivers-licence">
              {options?.map((item: any, index: any) => (
                <div
                  onClick={() => onFlowChange(item?.value)}
                  className={`flex justify-between items-center border ${
                    flow === item?.value
                      ? "border-primary border-[1.5px]"
                      : "border-borderSecondary"
                  } p-3 rounded-[8px] mt-3 ${
                    flow === item?.value ? "bg-primaryLight" : ""
                  }`}
                  key={index}
                >
                  <div className="flex">
                    <div>
                      <img src={item?.image} alt="" className="me-2" />
                    </div>
                    <div>
                      <Label
                        htmlFor={item?.value}
                        className={`text-[14px] font-[400] ${
                          flow === item?.value
                            ? "text-primaryText"
                            : "text-white"
                        } `}
                      >
                        {item?.label}
                      </Label>
                    </div>
                  </div>
                  <RadioGroupItem
                    id={item?.value}
                    value={item?.value}
                    checked={flow === item?.value}
                    onClick={() => onFlowChange(item?.value)}
                    aria-label={item?.value}
                  ></RadioGroupItem>
                </div>
              ))}
            </RadioGroup>
          </div>

          <Button
            className=" max-md:mb-[0px] w-full text-black bg-white rounded-[16px] mt-4 h-[56px] hover:opacity-90 hover:bg-white"
            onClick={handleContinue}
            disabled={loader}
          >
            {pageConfig?.buttonText || "Continue"}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Layout
      className={`${isMobile && "userInfoAnimation"} ${
        hideCamera && "bg-transparent"
      }`}
      logoBackgroundColor={"rgb(22,27,37)"}
      backgroundColor={"#161b25"}
    >
      {renderComponent()}
    </Layout>
  );
};

export default DocSelectionV2;
